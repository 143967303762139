
import { IonGrid, IonRow, IonCol, IonBadge, IonIcon } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import _ from "lodash";
import { defineComponent, ref } from "vue";
import Container from "@/views/layout/Container.vue";
import {
  barbellOutline,
  bookOutline,
  cogOutline,
  peopleOutline,
  compassOutline,
  clipboardOutline,
  informationOutline,
  timeOutline,
  mailOpenOutline,
  rocketOutline,
  checkmarkOutline
} from "ionicons/icons";
import { useI18n } from "vue-i18n";
import {
  PushNotifications,
  PushNotificationToken
} from "@capacitor/push-notifications";
import ApiService from "@/services/api.service";
import SwitchBack from "@/components/children/SwitchBack.vue";
import { Badge } from "@ionic-native/badge";
import CustomIcon from "@/components/icons/themes/default/CustomIcon.vue";
import HealthKit from "@/components/HealthKit.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    HealthKit,
    CustomIcon,
    SwitchBack,
    IonGrid,
    IonRow,
    IonCol,
    Container,
    IonBadge,
    IonIcon
  },

  data() {
    return {
      loading: false
    };
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const healthKitRef = ref(HealthKit);

    return {
      healthKitRef,
      t,
      router,
      bookOutline,
      mailOpenOutline,
      barbellOutline,
      cogOutline,
      peopleOutline,
      informationOutline,
      rocketOutline,
      timeOutline,
      compassOutline,
      clipboardOutline,
      checkmarkOutline
    };
  },
  computed: {
    ...mapGetters("permission", ["getPermission"]),
    ...mapGetters({
      hasMainAccount: 'auth/hasMainAccount',
    }),
    ...mapGetters({
      getIcon: "app/getIcon",
      getLabel: "app/getLabel"
    }),

    isProfessional(): boolean {
      return this.getPermission("user.type")?.toLowerCase() === "professional";
    },

    childrenNotificationsCount(): number {
      const childrenWithNotifications = _.filter(
        this.getPermission("children"),
        item => {
          return item.number_of_unread_alerts > 0;
        }
      );

      return childrenWithNotifications.length;
    }
  },
  methods: {
    async onDashboardButtonClick(route: string) {
      await this.$router.push(route);
    },
    ...mapActions("permission", ["fetchPermission"]),
    registerPush() {
      PushNotifications.requestPermissions().then(
        (result: { receive: string }) => {
          if (result.receive === "granted") {
            PushNotifications.register();
          } else {
            //Unable to register for push.
          }
        }
      );

      PushNotifications.addListener(
        "registration",
        (token: PushNotificationToken) => {
          ApiService.get(
            `permission?device_token=${token.value}&os=${encodeURIComponent(
              navigator.userAgent
            )}`
          );
        }
      );
    },

    registerBadges() {
      if (this.getPermission("user.show_badges")) {
        Badge.set(this.getPermission("totalBadges")).then(() => {
          //Badges set.
        });
      }
    },

    async init() {
      this.loading = true;
      await this.fetchPermission(this.hasMainAccount);
      this.registerBadges();

      if (this.isProfessional) {
        await this.$router.replace("/patients");
      } else {
        if (
          this.healthKitRef &&
          this.getPermission("View_own_measurements") !== null
        ) {
          if ( typeof this.healthKitRef['syncHealth'] === 'function') {
            this.healthKitRef?.syncHealth();
          }
        }
      }

      this.loading = false;
    },

    ionViewWillEnter() {
      this.init();
    }
  },

  created() {
    this.registerPush();
  }
});
