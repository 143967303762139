
import {defineComponent} from "vue";
export default defineComponent({
  name: 'CustomIcon',

  props: {
    name: {
      required: true,
      type: String,
    },
    classes: {
      default: '',
      type: String,
    }
  }
});
