import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-403a87a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "ion-no-margin ion-no-padding dashboard-title" }
const _hoisted_4 = { class: "dashboard-welcome" }
const _hoisted_5 = {
  key: 1,
  class: "ion-no-margin ion-no-padding ion-padding-bottom dashboard-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HealthKit = _resolveComponent("HealthKit")!
  const _component_IonCol = _resolveComponent("IonCol")!
  const _component_SwitchBack = _resolveComponent("SwitchBack")!
  const _component_IonBadge = _resolveComponent("IonBadge")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_CustomIcon = _resolveComponent("CustomIcon")!
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_IonGrid = _resolveComponent("IonGrid")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    name: _ctx.t('dashboard.dashboard'),
    "settings-button": true,
    "show-avatar": true
  }, {
    default: _withCtx(() => [
      (_ctx.getPermission('View_own_measurements'))
        ? (_openBlock(), _createBlock(_component_HealthKit, {
            key: 0,
            ref: "healthKitRef"
          }, null, 512))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_IonGrid, { class: "dashboard-buttons" }, {
          default: _withCtx(() => [
            _createVNode(_component_IonRow, null, {
              default: _withCtx(() => [
                _createVNode(_component_IonCol, {
                  class: "ion-text-center",
                  size: "12",
                  "size-lg": "12",
                  "size-md": "12"
                }, {
                  default: _withCtx(() => [
                    (_ctx.getLabel('welcomeText'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t(_ctx.getLabel("welcomeText"), {
                    name: _ctx.getPermission("user.first_name")
                  })), 1),
                          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t(_ctx.getLabel("welcomeTextDescription"))), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("h1", _hoisted_5, " Telerevalidatie "))
                  ]),
                  _: 1
                }),
                _createVNode(_component_IonCol, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SwitchBack)
                  ]),
                  _: 1
                }),
                (_ctx.getPermission('View_own_content_items'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 0,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/information')))
                        }, [
                          (_ctx.getPermission('badgeStatistics.contentItems') > 0)
                            ? (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 0,
                                class: "dashboard-badge",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPermission("badgeStatistics.contentItems")), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 1,
                                class: "dashboard-badge",
                                color: "success"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_IonIcon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('information'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t(_ctx.getLabel("contentItemsText"))), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('View_own_exercises'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 1,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push('/exercises')))
                        }, [
                          (_ctx.getPermission('badgeStatistics.exercises') > 0)
                            ? (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 0,
                                class: "dashboard-badge",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPermission("badgeStatistics.exercises")), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 1,
                                class: "dashboard-badge",
                                color: "success"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_IonIcon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('exercises'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t(_ctx.getLabel("exercisesText"))), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('View_messages'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 2,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.router.push('/messages')))
                        }, [
                          (_ctx.getPermission('badgeStatistics.messages') > 0)
                            ? (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 0,
                                class: "dashboard-badge",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPermission("badgeStatistics.messages")), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('messages'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t(_ctx.getLabel("messagesText"))), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('View_own_time_table'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 3,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.router.push('/schedule')))
                        }, [
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('timetable'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t("dashboard.timetable")), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('View_notepad'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 4,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.router.push('/notes')))
                        }, [
                          (_ctx.getPermission('badgeStatistics.notepad') > 0)
                            ? (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 0,
                                class: "dashboard-badge",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPermission("badgeStatistics.notepad")), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('notes'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t(_ctx.getLabel("notepadText"))), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('View_own_questionnaires'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 5,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.router.push('/tests')))
                        }, [
                          (_ctx.getPermission('badgeStatistics.tests') > 0)
                            ? (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 0,
                                class: "dashboard-badge",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPermission("badgeStatistics.tests")), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 1,
                                class: "dashboard-badge",
                                color: "success"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_IonIcon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('questionnaires'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t(_ctx.getLabel("questionnairesText"))), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isProfessional && _ctx.getPermission('showCareTeam') != false)
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 6,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onDashboardButtonClick('/team')))
                        }, [
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('team'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t("dashboard.team")), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('Have_goals'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 7,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.router.push('/goals')))
                        }, [
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('goals'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t("dashboard.goals")), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
              _ctx.getPermission('children') && _ctx.getPermission('children').length > 0
            )
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 8,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.router.push('/children')))
                        }, [
                          (_ctx.childrenNotificationsCount)
                            ? (_openBlock(), _createBlock(_component_IonBadge, {
                                key: 0,
                                class: "dashboard-badge",
                                color: "danger"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.childrenNotificationsCount), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('children'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t("dashboard.children")), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.getPermission('View_own_measurements'))
                  ? (_openBlock(), _createBlock(_component_IonCol, {
                      key: 9,
                      size: "6",
                      "size-lg": "3",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "dashboard-button",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.router.push('/health')))
                        }, [
                          _createVNode(_component_CustomIcon, {
                            class: "dashboard-icon dashboard-icon__component",
                            name: _ctx.getIcon('health'),
                            classes: "dashboard-icon dashboard-icon__image"
                          }, null, 8, ["name"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.t("dashboard.health")), 1)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}