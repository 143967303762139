
import {defineComponent} from 'vue';
import Button from '../Button.vue';
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import {mapActions, mapGetters} from "vuex";
import {toastController} from "@ionic/vue";

export default defineComponent({
  name: 'SwitchBack',
  components: {
    Button,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    return {
      t,
      router,
    }
  },
  computed: {
    ...mapGetters({
      hasMainAccount: 'auth/hasMainAccount',
    }),
  },
  methods: {
    ...mapActions({
      switchBackToMain: 'auth/switchBackToMain',
      clearItems: 'information/clearItems',
      changeTheme: 'app/changeTheme',
    }),
    async switchBack() {
      this.switchBackToMain();
      this.clearItems();
      this.changeTheme('default');

      const toast = await toastController.create({
        message: this.t('children.switchBackSuccess'),
        duration: 3000,
      });

      await toast.present();

      if (this.$route.name === 'dashboard') {
        window.location.reload();
      } else {
        this.router.replace('/');
      }
    }
  },
});
