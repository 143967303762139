import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.hasMainAccount)
    ? (_openBlock(), _createBlock(_component_Button, {
        key: 0,
        expand: "full",
        onClick: _ctx.switchBack
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('children.switchBack')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]))
    : _createCommentVNode("", true)
}