<template>
  <div></div>
</template>

<script lang="js">
import {defineComponent} from "vue";
import {Health} from '@ionic-native/health';
import moment from "moment";
import _ from "lodash";
import ApiService from "@/services/api.service";

export default defineComponent({
  name: 'HealthKit',

  data() {
    return {
      lastMeasurements: null,
      totalSteps: 0,
      stepsData: [],
    }
  },
  
  methods: {
    syncHealth() {
      Health.isAvailable().then(() => {
        Health.query({
          startDate: moment().startOf('day').toDate(),
          endDate: moment().toDate(),
          dataType: 'steps',
          limit: 1000
        }, (response) => {
          this.stepsData = response ?? [];
          this.totalSteps = _.sumBy(this.stepsData, 'value');
          this.saveSteps();
        }, () => {
          console.log('Unable to read health data');
        });
      })
    },

    async saveSteps() {
      const {data} = await ApiService.get('/sensor');
      const lastMeasurements = _.get(data, 'last_measurements.steps', '');
      this.lastMeasurements = moment.parseZone(lastMeasurements);

      try {
        await ApiService.post('/sensor/steps', {
          packet: (this.stepsData.map((value) => {
                const startDate = moment(value.startDate).format('YYYY-MM-DD HH:mm:ss').toString();
                const endDate = moment(value.endDate).format('YYYY-MM-DD HH:mm:ss').toString();

                if (this.lastMeasurements.isValid() && moment.parseZone(endDate).diff(this.lastMeasurements) <= 0) {
                  return false;
                }

                return {
                  id: value.id,
                  source: 'healthkit',
                  measurement_from: startDate,
                  measurement_till: endDate,
                  amount: value.value,
                }
              })
          ).filter((value) => {
            return value !== false
          })
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
});
</script>